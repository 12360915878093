import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import {SectionPage, SectionTitle, MainPanel, LeftPanel, RightPanel, MarkdownContent, Container} from "../components/Section"
import { SidebarSticky } from "../components/Sidebar"
import {
  HeroSection,
  HeroCover,
  LeftHero,
  RightHero,
  HeroPageTitle,
  HeroDesc,
  HeroFormWrap,
  HeroFormTitle,
  HeroFormBody,
} from "../components/Hero"
import TabProductCardList from "../components/TabProductCardList"
import CallToAction from "../components/CallToAction"
import DesignPlan from "../components/DesignPlan"
import AccreditedBusiness from "../components/AccreditedBusiness"
import QuickContactForm from "../components/QuickContactForm"

const OtherBuildingsPage = pageData => {
  const productData = pageData.data.allContentfulProduct.edges.filter(
    edge =>
      edge.node.productCategory &&
      edge.node.productCategory.name &&
      edge.node.productRoofType &&
      edge.node.productRoofType.name
  )
  let otherProductData = []
  productData.forEach(function(item, index) {
    if (
      item.node.productCategory.name !== "Carports" &&
      item.node.productCategory.name !== "Garages" &&
      item.node.productCategory.name !== "Barns" &&
      item.node.productCategory.name !== "RV Covers" &&
      item.node.productCategory.name !== "Carolina Barns" &&
      item.node.productCategory.name !== "Seneca Barns"
    ) {
      otherProductData.push(item)
    }
  })
  return (
    <Layout location={pageData.location}>
      <SEO
        title="Custom Steel Building Manufacturer - ProBuilt Steel Buildings"
        description="ProBuilt Steel is a leading manufacturers of durable, customizable steel buildings. Get expert guidance and build with confidence. Explore our wide range of options and get a free quote."
      />
      <HeroSection>
        <div className="container">
          <HeroCover>
            <LeftHero>
              <HeroPageTitle>Steel Buildings</HeroPageTitle>
              <HeroDesc>
                <p>Probuilt Steel Buildings provides a wide array of custom steel buildings with free delivery and installation service. Our building range includes various sizes and styles that will help you store the family car, tuck away expensive equipment and supplies, or create your own workspace. No matter what project you take on, we have a structure that can help. These steel buildings are precision-engineered by experts to ensure a long-lasting performance against extreme weather conditions and wear and tear.</p>
                <p>Here are some of the unique sizes we have: <strong>18x20, 20x40, 30x40, 40x50</strong></p>
                <ul>
                  <li>Florida Certified Structures</li>
                  <li>Free Delivery and Installation</li>
                  <li>100% Customizable Structures</li>
                  <li>Affordable Pricing</li>
                  <li>Financing and RTO Available</li>
                </ul>
              </HeroDesc>
            </LeftHero>
            <RightHero>
              <HeroFormWrap>
                <HeroFormTitle>Get In Touch</HeroFormTitle>
                <HeroFormBody>
                  <QuickContactForm
                    location={pageData.location}
                    formName="Steel Building Contact Form"
                  />
                </HeroFormBody>
              </HeroFormWrap>
            </RightHero>
          </HeroCover>
        </div>
      </HeroSection>

      <SectionPage
      ept="80px"
      epb="80px"
      xpt="60px"
      xpb="60px"
      pt="40px"
      pb="40px"
      bg="#F4FBFF">
        <SectionTitle>Our Most Popular Steel Buildings</SectionTitle>
        <div className="container steel-building-product">
          <TabProductCardList
            data={otherProductData}
            category="Buildings"
            location={pageData.location}
          />
        </div>
      </SectionPage>
      <CallToAction />
      <SectionPage 
          ept="80px"
          epb="80px"
          xpt="60px"
          xpb="60px"
          pt="40px"
          pb="40px"
          bg="#fff">
          <Container>
            <MainPanel>
								<LeftPanel>
                  <MarkdownContent>
                    <h2>Custom Steel Building Sizes</h2>
                    <p>Our structures come in many sizes and configurations! We offer options from 18’ all the way up to 70’ wide, so you’ll never have to worry about finding the perfect fit for your property. Create a small storage shed to protect equipment and tools, or build a guest house for family and visitors. You'll have the right structure for the job, no matter what you're looking for.</p>
                    
                    <p>Explore some of our available options, or you can create your own, using our <a href="https://probuiltsteel.sensei3d.com/">3D Building Designer</a> tool. With the help of this tool you can view, edit, and customize your metal building design according to your needs. Pick the right size for your needs and make it your own.</p>

                    <h2>Metal Building Kits and Their Components</h2>
                    <p>Probuilt also offers an extensive selection of <Link to="/steel-buildings/metal-buildings-kits/">steel building kits</Link>. These kits feature all the same components found in our standard buildings, except you handle hiring installation crews. Our kits come with:</p>
                    <ul>
                      <li>Primary Framing</li>
                      <li>Secondary Framing</li>
                      <li>Wall Paneling</li>
                      <li>Roof Paneling</li>
                      <li>Doors</li>
                      <li>Windows</li>
                      <li>Accessories</li>
                      <li>Fasteners</li>
                      <li>Anchor Systems</li>
                    </ul>
                    <p>But why waste time and money hiring third-party contractors when ALL our buildings include delivery and installation? Our professional crews will handle every aspect of installation, so you don't have to. All you'll need to do is prepare your foundation, handle the permits, and watch it all come to life.</p>
                    
                    <h2>Metal Building Prices</h2>
                    <p>Generally, you can expect steel buildings to range between $20 to $40 per sq. ft. On average, the cost of an 18’x20’ storage building is around $6,000 to $9,000, a 30’x40’ metal building is around $18,000 to $22,000, and a 48’x60’ commercial building can cost around 46,000 to 50,000 and more. However, these <Link to="/metal-building-prices/">metal building prices</Link> vary based on numerous factors. Raw steel prices, the customization options you choose, and even your location can contribute to the final price of your structure.</p>

                    <p>Probuilt Steel Buildings is proud to be a leading provider of metal buildings, steel garages, and carports, and we’re here to serve you for all your structural needs. Our buildings are made from high-quality steel components and engineered to take on any project you have in mind. Call our experts today at <a href="tel:8777541818" aria-label="(877) 754-1818">(877) 754-1818</a> to get started. We’ll walk you through the whole process and deliver results that go the distance for you.</p>

                  </MarkdownContent>
								</LeftPanel>
								<RightPanel>
									<SidebarSticky className='isSticky'>
                    <DesignPlan />
                    <AccreditedBusiness />
									</SidebarSticky>
								</RightPanel>
							</MainPanel>
          </Container>
        </SectionPage>      
    </Layout>
  )
}

export default OtherBuildingsPage

export const pageQuery = graphql`
  query OtherBuildingsPageQuery {
    allContentfulProduct(sort: { fields: skuNumber }) {
      edges {
        node {
          id
          senseiProductId
          skuNumber
          imageSrc
          image {
            fluid(maxWidth: 700, quality: 100) {
              base64
              sizes
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          width
          length
          height
          price
          priceDescription
          gauge
          productServices {
            spaceId
            service
            image {
              fluid(maxWidth: 45, quality: 100) {
                aspectRatio
                base64
                sizes
                src
                srcWebp
                srcSetWebp
                srcSet
              }
            }
          }
          description {
            description
          }
          productRoofType {
            name
          }
          productCategory {
            name
          }
          numberOfSale
          canBeUsedAs {
            content
          }
        }
      }
    }
  }
`
